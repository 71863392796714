<template>
  <v-row no-gutters class="flex-column">
    <v-card tile outlined dark>
      <v-card-text>
        <h4>
          Informasi Tantangan
          <v-btn icon @click="$refs.challenge.reset()" class="ml-2">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </h4>
        <v-form ref="challenge">
          <v-row>
            <v-col>
              <v-autocomplete
                label="Event"
                v-model="form.data.challenge.event_id"
                :rules="form.rules.event"
                :items="allEvents"
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                label="Title"
                v-model="form.data.challenge.title"
                :rules="form.rules.title"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-textarea
            label="Pertanyaan"
            v-model="form.data.challenge.description"
            :rules="form.rules.description"
          ></v-textarea>
          <v-textarea
            label="Jawaban"
            v-model="form.data.challenge.answer"
            :rules="form.rules.answer"
          ></v-textarea>
          <v-file-input
            label="Gambar"
            v-model="form.data.challenge.image"
            :rules="form.rules.image"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          large
          @click="saveData"
          :loading="form.loading"
          :disabled="form.loading"
        >
          <span>simpan</span>
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar :v-model="snackbar.vm" :color="snackbar.color" multi-line>
      <v-icon left>{{ snackbar.icon }}</v-icon>
      {{ snackbar.text }}
      <template v-slot:action>
        <v-btn text @click="snackbar.vm = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        loading: false,
        data: {
          challenge: {
            event_id: null,
            title: null,
            description: null,
            answer: null,
            image: null,
          },
        },
        rules: {
          title: [(v) => !!v || "Judul harus diisi"],
          start_date: [(v) => !!v || "Tanggal mulai harus diisi"],
          end_date: [(v) => !!v || "Tanggal selesai harus diisi"],
          participant_limit: [(v) => !!v || "Batas peserta harus diisi"],
          shipment_cost: [(v) => !!v || "Pilih gratis ongkir"],
          image: [
            (v) => !!v || "Pilih banner event",
            (value) =>
              !value ||
              value.size < 2000000 ||
              "Ukuran file banner harus kurang dari 2 MB!",
          ],
          event: [(v) => !!v || "Pilih event"],
          description: [(v) => !!v || "Pertanyaan harus diisi"],
          max_winners: [(v) => !!v || "Batas pemenang harus diisi"],
          answer: [(v) => !!v || "Jawaban harus diisi"],
        },
      },
      snackbar: {
        vm: false,
        color: null,
        icon: null,
        text: null,
      },
    };
  },
  methods: {
    ...mapActions([
      "fetchEvents",
      "fetchChallenges",
      "addChallenge",
    ]),
    saveData() {
      //prevent user from another submit
      this.form.loading = true;

      //get form data
      let data = this.form.data.challenge;

      //check if form is valid
      let isValid = this.$refs.challenge.validate();

      //initiate formData
      let formData = new FormData();

      //add data to formData
      formData.append("event_id", data.event_id);
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("answer", data.answer);
      formData.append("image", data.image);

      //check if form is valid
      if (isValid) {
        if(this.addChallenge(formData)){
          this.showNotification("success")
          this.form.loading = false
        } else {
          this.showNotification("error")
          this.form.loading = false
        }
      } else {
        this.showNotification("error")
        this.form.loading = false
      }
    },
    showNotification(status){
      this.snackbar.vm = false
      switch(status){
        case "success":
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check-circle";
          this.snackbar.text = "Data berhasil disimpan";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close-circle";
          this.snackbar.text = "Data gagal disimpan";
          break;
      }
    }
  },
  computed: {
    ...mapGetters(["allEvents", "allChallenges"]),
  },
  created() {
    this.fetchEvents();
    this.fetchChallenges();
  },
};
</script>